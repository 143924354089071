import logo from './logo.svg';
import './App.css';
import Header from './template/Header';

function App() {
  return (
    <div>
      <Header />

      <div style={{
        textAlign: 'center',
        padding: 30
      }}>
        <div style={{
          fontSize: 46
        }}>Ferramentas</div>
        <div style={{
          fontSize: 32
        }}>Crie projetos que possam ser reutilizados e vendidos para outras pessoas</div>
      </div>
    </div>
  );
}

export default App;